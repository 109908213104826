@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.main{
 padding: 6rem;
  /* background-color: #212121; */
  color: whitesmoke;
  flex-direction: column;
}

.main-header{
  font-family: 'Montserrat', sans-serif;
}

.create-form label{
  color: whitesmoke !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px !important;
}